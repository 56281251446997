<template>
  <div>
    <b-container class="mt-4">
      <b-form @submit="sendGroup">
        <b-card header-class="header">
          <template #header>
            <label
              v-text="'Yarışma Grubu Oluştur'"
              class="mr-2 text-white"
            ></label>
          </template>
          <b-row class="mb-4">
            <b-col>Kulüp:</b-col>
            <b-col>
              <b-form-select
                v-model="item.club"
                :options="clubs"
                text-field="name"
                @change="clearPersonel()"
                value-field="id"
                required
              >
              </b-form-select
            ></b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>Kategori:</b-col>
            <b-col>
              <b-select
                :options="getCategorySelections"
                text-field="title"
                value-field="id"
                @change="clearPersonel()"
                v-model="item.category"
                required
              ></b-select
            ></b-col>
          </b-row>
          <b-row class="mb-4" v-if="singleCompetition.branch !== '60'">
            <b-col>Tekne Sınıfı:</b-col>
            <b-col>
              <b-select
                :options="getBoatClassSelections"
                text-field="title"
                @change="clearPersonel()"
                value-field="id"
                v-model="item.boatType"
                required
              ></b-select
            ></b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col> Mesafe </b-col>
            <b-col>
              <b-select
                :options="getDistanceSelections"
                text-field="title"
                value-field="id"
                v-model="item.distance"
                required
              ></b-select>
            </b-col>
          </b-row>
          <b-row class="mb-2" v-if="checkBoatType() > 0">
            <b-col> Personel 1 </b-col>
            <b-col>
              <b-select
                :options="clubMembers[0]"
                text-field="title"
                v-if="individualPersonel1 == null"
                :key="1"
                value-field="value"
                v-model="item.personelId1"
                required
              ></b-select>
              <b-row v-else
                ><b-col
                  >Ferdi Sporcu:
                  {{ individualPersonel1.nameSurname.split(",")[0] }}</b-col
                >
                <b-button
                  class="my-2 my-sm-0"
                  type="submit"
                  @click="individualPersonel1 = null"
                  ><b-icon-x /></b-button
              ></b-row>
            </b-col>
            <b-col
              ><b-btn
                @click="addIndividualPersonal(0)"
                v-b-modal.individualModal
                >Ferdi Sporcu Ekle</b-btn
              ></b-col
            >
          </b-row>

          <b-row class="mb-2" v-if="checkBoatType() > 1">
            <b-col> Personel 2 </b-col>
            <b-col>
              <b-select
                :options="clubMembers[0]"
                v-if="individualPersonel2 == null"
                text-field="title"
                :key="2"
                required
                value-field="value"
                v-model="item.personelId2"
              ></b-select>
              <b-row v-else
                ><b-col
                  >Ferdi Sporcu:
                  {{ individualPersonel2.nameSurname.split(",")[0] }}</b-col
                >
                <b-button
                  class="my-2 my-sm-0"
                  type="submit"
                  @click="individualPersonel2 = null"
                  ><b-icon-x /></b-button
              ></b-row>
            </b-col>
            <b-col
              ><b-btn
                @click="addIndividualPersonal(1)"
                v-b-modal.individualModal
                >Ferdi Sporcu Ekle</b-btn
              ></b-col
            >
          </b-row>

          <b-row class="mb-2" v-if="checkBoatType() > 2">
            <b-col> Personel 3 </b-col>
            <b-col>
              <b-select
                :options="clubMembers[0]"
                v-if="individualPersonel3 == null"
                text-field="title"
                :key="3"
                required
                value-field="value"
                v-model="item.personelId3"
              ></b-select>
              <b-row v-else
                ><b-col
                  >Ferdi Sporcu:
                  {{ individualPersonel3.nameSurname.split(",")[0] }}</b-col
                >
                <b-button
                  class="my-2 my-sm-0"
                  type="submit"
                  @click="individualPersonel3 = null"
                  ><b-icon-x /></b-button
              ></b-row>
            </b-col>
            <b-col>
              <b-btn
                @click="addIndividualPersonal(2)"
                v-b-modal.individualModal
              >
                Ferdi Sporcu Ekle
              </b-btn>
            </b-col>
          </b-row>

          <b-row class="mb-2" v-if="checkBoatType() > 3">
            <b-col> Personel 4 </b-col>
            <b-col>
              <b-select
                :options="clubMembers[0]"
                v-if="individualPersonel4 == null"
                required
                text-field="title"
                :key="4"
                value-field="value"
                v-model="item.personelId4"
              ></b-select>
              <b-row v-else
                ><b-col
                  >Ferdi Sporcu:
                  {{ individualPersonel4.nameSurname.split(",")[0] }}</b-col
                >
                <b-button
                  class="my-2 my-sm-0"
                  type="submit"
                  @click="individualPersonel4 = null"
                  ><b-icon-x /></b-button
              ></b-row>
            </b-col>
            <b-col
              ><b-btn
                @click="addIndividualPersonal(3)"
                v-b-modal.individualModal
                >Ferdi Sporcu Ekle</b-btn
              ></b-col
            >
          </b-row>

          <b-row class="mb-2" v-if="checkBoatType() > 4">
            <b-col> Yedek Personel </b-col>
            <b-col>
              <b-select
                :options="clubMembers[0]"
                v-if="individualPersonelBackup == null"
                required
                text-field="title"
                :key="5"
                value-field="value"
                v-model="item.personelIdBackup"
              ></b-select>
              <b-row v-else
                ><b-col
                  >Ferdi Sporcu:
                  {{
                    individualPersonelBackup.nameSurname.split(",")[0]
                  }}</b-col
                >
                <b-button
                  class="my-2 my-sm-0"
                  type="submit"
                  @click="individualPersonelBackup = null"
                  ><b-icon-x /></b-button
              ></b-row>
            </b-col>
            <b-col
              ><b-btn
                @click="addIndividualPersonal(4)"
                v-b-modal.individualModal
                >Ferdi Sporcu Ekle</b-btn
              ></b-col
            >
          </b-row>
          <b-btn @click="sendGroup"></b-btn>
        </b-card>
      </b-form>
    </b-container>

    <div>
      <b-modal
        id="individualModal"
        title="Ferdi Sporcu Ekle"
        @ok="addIndividual"
      >
        <b-row align-v="center">
          <b-col>
            <b-form-input
              class="mr-sm-2"
              v-model="searchingSsn"
              placeholder="Ferdi sporcu TCKN:"
            ></b-form-input>
          </b-col>
          <b-col align-self="right">
            <b-btn @click="searchIndividualPersonel">Ara</b-btn>
          </b-col>
        </b-row>
        <div>
          <b-row class="mt-4">
            <b-col>TCKN:</b-col>
            <b-col>{{
              individualPersonel ? individualPersonel.ssn : "Bulunamadı"
            }}</b-col>
          </b-row>
          <b-row>
            <b-col>Ad:</b-col>
            <b-col>{{
              individualPersonel ? individualPersonel.name : "Bulunamadı"
            }}</b-col>
          </b-row>
          <b-row>
            <b-col>Soyad:</b-col>
            <b-col>{{
              individualPersonel ? individualPersonel.surname : "Bulunamadı"
            }}</b-col>
          </b-row>
          <b-row>
            <b-col>Şehir:</b-col>
            <b-col>{{
              individualPersonel.contact
                ? this.cities.filter(
                    (a) =>
                      a.value == individualPersonel.contact.cityWhereLocated
                  )[0].text
                : ""
            }}</b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>



<script>
import participantType from "../../../store/constants/participantType";
import { mapGetters } from "vuex";
import selectionConstants from "@/store/constants/selections";
import cities from "@/store/constants/cities";
export default {
  data() {
    return {
      participantType,
      selectedIndividualIndex: 0,
      cities,
      selectedDistance: "",
      selectedCategory: "",
      searchingSsn: "",
      individualPersonel1: null,
      individualPersonel2: null,
      individualPersonel3: null,
      individualPersonel4: null,
      individualPersonelBackup: null,
      item: {
        club: 0,
        category: "",
        boatType: "",
        distance: "",
        competitionId: this.$route.params.id,
        clubId: null,
        personelId1: null,
        personelId2: null,
        personelId3: null,
        personelId4: null,
        personelIdBackup: null,
        formOfParticipation: "sportState",
      },
      fields: [
        { key: "category", label: "Kategori" },
        { key: "distance", label: "Mesafe" },
        { key: "personel", label: "Personel" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("initManagement");
    this.$store
      .dispatch("initSingleCompetition", this.$route.params.id)
      .then((data) => {
        if (data.result.branch == "60") {
          this.item.boatType = "61";
        }
      });
    this.$store.commit("setClubCompetitionPersonel", []);
    this.$store.dispatch("getClubNames");
  },
  computed: {
    ...mapGetters({
      getSelections: "getManagementItems",
      clubMembers: "getClubCompetitionPersonel",
      singleCompetition: "getSingleCompetition",
      getClubNames: "getClubNames",
      individualPersonel: "getIndividualPersonel",
    }),

    getBoatClassSelections: {
      get() {
        return [{ title: "Seçin", id: "", option: 0 }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          })
        );
      },
    },

    clubs: {
      get() {
        return [{ name: "Seçin", id: 0 }].concat(this.getClubNames);
      },
    },

    getCategorySelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections
            .filter((e) => {
              return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
            })
            .filter((el) => {
              return this.singleCompetition.category
                .split(";")
                .includes(String(el.id));
            })
        );
      },
    },
    getDistanceSelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.DISTANCE; //DISTANCE FILTER
          })
        );
      },
    },
  },
  methods: {
    async sendGroup(event) {
      event.preventDefault();
      //If there are duplicate member
      var personelList = [];
      if (this.individualPersonel1 != null) {
        personelList.push(this.individualPersonel1.id);
      } else if (this.individualPersonel2 != null) {
        personelList.push(this.individualPersonel2.id);
      } else if (this.individualPersonel3 != null) {
        personelList.push(this.individualPersonel3.id);
      } else if (this.individualPersonel4 != null) {
        personelList.push(this.individualPersonel4.id);
      } else if (this.item.personelId1 != null) {
        personelList.push(this.item.personelId1);
      } else if (this.item.personelId2 != null) {
        personelList.push(this.item.personelId2);
      } else if (this.item.personelId3 != null) {
        personelList.push(this.item.personelId3);
      } else if (this.item.personelId4 != null) {
        personelList.push(this.item.personelId4);
      }
      if (new Set(personelList).size !== personelList.length) {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Hata!",
            body: "Bir tekneye aynı kişiyi birden fazla kez ekleyemezsiniz!",
            type: "danger",
          },
        ]);
      } else {
        if (this.individualPersonel1 != null) {
          this.item.personelId1 = this.individualPersonel1.id;
        }
        if (this.individualPersonel2 != null) {
          this.item.personelId2 = this.individualPersonel2.id;
        }
        if (this.individualPersonel3 != null) {
          this.item.personelId3 = this.individualPersonel3.id;
        }
        if (this.individualPersonel4 != null) {
          this.item.personelId4 = this.individualPersonel4.id;
        }
        var res = await this.$store.dispatch("addFederationCompetitionGroup", {
          personelId: this.$store.getters.getUser.id,
          item: this.item,
        });
        if (res.success == true) {
          this.$store.dispatch("notification/setNotifications", [
            {
              title: "Başarılı!",
              body: "Kaydınız başarıyla alındı",
              type: "success",
            },
          ]);
        }
        this.$router.push({
          name: "FederationGroups",
          params: {
            clubId: this.$store.getters.getUser.id,
            competitionId: this.$route.params.id,
          },
        });
      }
    },

    clearPersonel() {
      this.item.personelId1 = null;
      this.item.personelId2 = null;
      this.item.personelId3 = null;
      this.item.personelId4 = null;
      if (this.item.category) {
        this.$store.dispatch("initClubCompetitionPersonel", {
          role: "sportState",
          clubId: this.item.club,
          category: this.item.category,
          index: 0,
          competitionId: this.$route.params.id,
        });
      } else {
        this.$store.commit("updateClubCompetitionPersonel", {
          "": "",
          data: [
            { title: "Seçin", id: "" },
            { label: "Erkek", options: [] },
            { label: "Kadın", options: [] },
          ],
        });
      }
    },
    checkBoatType() {
      if (this.item.category && this.item.club) {
        return this.getBoatClassSelections.filter((e) => {
          return e.id == this.item.boatType;
        })[0].option;
      } else {
        return false;
      }
    },
    addIndividualPersonal(index) {
      this.selectedIndividualIndex = index;
    },
    async searchIndividualPersonel() {
      this.$store.dispatch("searchIndividualPersonel", {
        ssn: this.searchingSsn,
      });
    },

    addIndividual() {
      if (this.selectedIndividualIndex == 0) {
        this.individualPersonel1 = this.individualPersonel;
      } else if (this.selectedIndividualIndex == 1) {
        this.individualPersonel2 = this.individualPersonel;
      } else if (this.selectedIndividualIndex == 2) {
        this.individualPersonel3 = this.individualPersonel;
      } else if (this.selectedIndividualIndex == 3) {
        this.individualPersonel4 = this.individualPersonel;
      }
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>